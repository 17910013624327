import React from "react";
import './squares.css';

const Squares = () => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 3185.9 2994.4" style={{enableBackground:"new 0 0 3185.9 2994.4"}} xmlSpace="preserve">
<defs>
	<filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="-49.2" y="-45" width="3273.9" height="3083.9">
		<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
	</filter>
</defs>
<mask maskUnits="userSpaceOnUse" x="-49.2" y="-45" width="3273.9" height="3083.9" id="SVGID_1_">
	<g className="st0">
	</g>
</mask>
<g className="st1">
	
		<rect x="209.1" y="607.3" transform="matrix(0.7975 -0.6033 0.6033 0.7975 -581.6102 1260.9612)" className="st2" width="2757.3" height="1779.2"/>
	
		<rect x="209.1" y="607.3" transform="matrix(0.7975 -0.6033 0.6033 0.7975 -581.6102 1260.9612)" className="st3 color-palette-stroke" width="2757.3" height="1779.2"/>
</g>
<g>
	
		<rect x="247" y="631.4" transform="matrix(0.8001 -0.5999 0.5999 0.8001 -580.7984 1251.4634)" className="st2" width="2680.6" height="1731.7"/>
	
		<rect x="247" y="631.4" transform="matrix(0.8001 -0.5999 0.5999 0.8001 -580.7984 1251.4634)" className="st4 color-palette-stroke" width="2680.6" height="1731.7"/>
</g>
<g>
	<polygon className="st2" points="3133.1,1398 1042.6,2950.6 40.8,1596.8 2131.3,44.3 	"/>
	<polygon className="st5 color-palette-stroke" points="3133.1,1398 1042.6,2950.6 40.8,1596.8 2131.3,44.3 	"/>
</g>
<g>
	<polygon className="st2" points="3087.7,1409.7 1051.4,2906.8 85.4,1585.6 2121.7,88.5 	"/>
	<polygon className="st6 color-palette-stroke" points="3087.7,1409.7 1051.4,2906.8 85.4,1585.6 2121.7,88.5 	"/>
</g>
<g>
	<polygon className="st2" points="3042.3,1421.3 1060.2,2863 130,1574.4 2112,132.8 	"/>
	<polygon className="st7 color-palette-stroke" points="3042.3,1421.3 1060.2,2863 130,1574.4 2112,132.8 	"/>
</g>
<g>
	<polygon className="st2" points="2996.9,1433 1069.1,2819.2 174.6,1563.2 2102.4,177 	"/>
	<polygon className="st8 color-palette-stroke" points="2996.9,1433 1069.1,2819.2 174.6,1563.2 2102.4,177 	"/>
</g>
<g>
	<polygon className="st2" points="2951.5,1444.7 1077.9,2775.4 219.2,1552 2092.8,221.3 	"/>
	<polygon className="st9 color-palette-stroke" points="2951.5,1444.7 1077.9,2775.4 219.2,1552 2092.8,221.3 	"/>
</g>
<g>
	<polygon className="st2" points="2906.1,1456.3 1086.7,2731.6 263.8,1540.8 2083.1,265.5 	"/>
	<polygon className="st10 color-palette-stroke" points="2906.1,1456.3 1086.7,2731.6 263.8,1540.8 2083.1,265.5 	"/>
</g>
<g>
	<polygon className="st2" points="2860.7,1468 1095.6,2687.8 308.4,1529.7 2073.5,309.8 	"/>
	<polygon className="st11 color-palette-stroke" points="2860.7,1468 1095.6,2687.8 308.4,1529.7 2073.5,309.8 	"/>
</g>
<g>
	<polygon className="st2" points="2815.2,1479.6 1104.4,2644.1 353,1518.5 2063.9,354 	"/>
	<polygon className="st12 color-palette-stroke" points="2815.2,1479.6 1104.4,2644.1 353,1518.5 2063.9,354 	"/>
</g>
<g>
	<polygon className="st2" points="2769.8,1491.3 1113.2,2600.3 397.6,1507.3 2054.2,398.3 	"/>
	<polygon className="st13 color-palette-stroke" points="2769.8,1491.3 1113.2,2600.3 397.6,1507.3 2054.2,398.3 	"/>
</g>
<g>
	<polygon className="st2" points="2724.4,1503 1122,2556.5 442.2,1496.1 2044.6,442.6 	"/>
	<polygon className="st14 color-palette-stroke" points="2724.4,1503 1122,2556.5 442.2,1496.1 2044.6,442.6 	"/>
</g>
<g>
	<polygon className="st2" points="2679,1514.6 1130.9,2512.7 486.8,1484.9 2035,486.8 	"/>
	<polygon className="st15 color-palette-stroke" points="2679,1514.6 1130.9,2512.7 486.8,1484.9 2035,486.8 	"/>
</g>
<g>
	<polygon className="st2" points="2633.6,1526.3 1139.7,2468.9 531.4,1473.7 2025.4,531.1 	"/>
	<polygon className="st16 color-palette-stroke" points="2633.6,1526.3 1139.7,2468.9 531.4,1473.7 2025.4,531.1 	"/>
</g>
<g>
	<polygon className="st2" points="2588.2,1537.9 1148.5,2425.1 576.1,1462.5 2015.7,575.3 	"/>
	<polygon className="st17 color-palette-stroke" points="2588.2,1537.9 1148.5,2425.1 576.1,1462.5 2015.7,575.3 	"/>
</g>
<g>
	<polygon className="st2" points="2542.8,1549.6 1157.4,2381.3 620.7,1451.3 2006.1,619.6 	"/>
	<polygon className="st18 color-palette-stroke" points="2542.8,1549.6 1157.4,2381.3 620.7,1451.3 2006.1,619.6 	"/>
</g>
<g>
	<polygon className="st2" points="2497.4,1561.3 1166.2,2337.5 665.3,1440.1 1996.5,663.8 	"/>
	<polygon className="st19 color-palette-stroke" points="2497.4,1561.3 1166.2,2337.5 665.3,1440.1 1996.5,663.8 	"/>
</g>
<g>
	<polygon className="st2" points="2452,1572.9 1175,2293.7 709.9,1428.9 1986.8,708.1 	"/>
	<polygon className="st20 color-palette-stroke" points="2452,1572.9 1175,2293.7 709.9,1428.9 1986.8,708.1 	"/>
</g>
<g>
	<polygon className="st2" points="2406.5,1584.6 1183.8,2250 754.5,1417.7 1977.2,752.4 	"/>
	<polygon className="st21 color-palette-stroke" points="2406.5,1584.6 1183.8,2250 754.5,1417.7 1977.2,752.4 	"/>
</g>
<g>
	<polygon className="st2" points="2361.1,1596.2 1192.7,2206.2 799.1,1406.5 1967.6,796.6 	"/>
	<polygon className="st22 color-palette-stroke" points="2361.1,1596.2 1192.7,2206.2 799.1,1406.5 1967.6,796.6 	"/>
</g>
<g>
	<polygon className="st2" points="2315.7,1607.9 1201.5,2162.4 843.7,1395.4 1957.9,840.9 	"/>
	<polygon className="st23 color-palette-stroke" points="2315.7,1607.9 1201.5,2162.4 843.7,1395.4 1957.9,840.9 	"/>
</g>
<g>
	<polygon className="st2" points="2270.3,1619.6 1210.3,2118.6 888.3,1384.2 1948.3,885.1 	"/>
	<polygon className="st24 color-palette-stroke" points="2270.3,1619.6 1210.3,2118.6 888.3,1384.2 1948.3,885.1 	"/>
</g>
<g>
	<polygon className="st2" points="2224.9,1631.2 1219.2,2074.8 932.9,1373 1938.7,929.4 	"/>
	<polygon className="st25 color-palette-stroke" points="2224.9,1631.2 1219.2,2074.8 932.9,1373 1938.7,929.4 	"/>
</g>
<g>
	<polygon className="st2" points="2179.5,1642.9 1228,2031 977.5,1361.8 1929,973.6 	"/>
	<polygon className="st26 color-palette-stroke" points="2179.5,1642.9 1228,2031 977.5,1361.8 1929,973.6 	"/>
</g>
<g>
	<polygon className="st2" points="2134.1,1654.5 1236.8,1987.2 1022.1,1350.6 1919.4,1017.9 	"/>
	<polygon className="st27 color-palette-stroke" points="2134.1,1654.5 1236.8,1987.2 1022.1,1350.6 1919.4,1017.9 	"/>
</g>
<g>
	<polygon className="st2" points="2088.7,1666.2 1245.6,1943.4 1066.7,1339.4 1909.8,1062.1 	"/>
	<polygon className="st28 color-palette-stroke" points="2088.7,1666.2 1245.6,1943.4 1066.7,1339.4 1909.8,1062.1 	"/>
</g>
<g>
	<polygon className="st2" points="2043.3,1677.9 1254.5,1899.6 1111.4,1328.2 1900.1,1106.4 	"/>
	<polygon className="st29 color-palette-stroke" points="2043.3,1677.9 1254.5,1899.6 1111.4,1328.2 1900.1,1106.4 	"/>
</g>
<g>
	<polygon className="st2" points="1997.9,1689.5 1263.3,1855.9 1156,1317 1890.5,1150.7 	"/>
	<polygon className="st30 color-palette-stroke" points="1997.9,1689.5 1263.3,1855.9 1156,1317 1890.5,1150.7 	"/>
</g>
<g>
	<polygon className="st2" points="1952.4,1701.2 1272.1,1812.1 1200.6,1305.8 1880.9,1194.9 	"/>
	<polygon className="st31 color-palette-stroke" points="1952.4,1701.2 1272.1,1812.1 1200.6,1305.8 1880.9,1194.9 	"/>
</g>
<g>
	<polygon className="st2" points="1907,1712.8 1281,1768.3 1245.2,1294.6 1871.3,1239.2 	"/>
	<polygon className="st32 color-palette-stroke" points="1907,1712.8 1281,1768.3 1245.2,1294.6 1871.3,1239.2 	"/>
</g>
<g>
	<rect x="1289.8" y="1283.4" className="st2" width="571.8" height="441.1"/>
	<rect x="1289.8" y="1283.4" className="st33 color-palette-stroke" width="571.8" height="441.1"/>
</g>
</svg>
);

export default Squares;
