import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import Squares from "./icon/squares";
import PortableText from "./portableText";
import { gsap } from 'gsap';
import VisibilitySensor from 'react-visibility-sensor';

import * as styles from "../styles/components/home-content-block.module.css";

const ContentBlock = (props) => {

  const {
    _rawTitle,
    _rawSubtitle,
    _rawContent,
    button,
    buttonExtendedReality
  } = props;

  const tl = gsap.timeline({paused:true});

  const [visible, setVisible] = useState(false);

  function onChangeVisibility (isVisible) {
    setVisible(isVisible);
  }

  useEffect(() => {
    const gs = gsap.utils.toArray(".squares-animation svg g");
    if(visible) {
      gs.forEach(g => {
        tl.to(g, {
          duration: 0.15,
          opacity: 1,
          ease: 'none',
        });
      });
      gsap.to(tl, {
        duration: 4,
        progress: 1,
        ease:"power1.out",
      });
    }
  });


  return (
    <div className={styles.root} id="content">
      <VisibilitySensor
        onChange={onChangeVisibility} 
        offset={{bottom: 50}}
        active={!visible}
        partialVisibility={true}
      >
        <div className={cn(styles.bg, "squares-animation color-palette-svg-stroke")}>
          <Squares />
        </div>
      </VisibilitySensor>
      <Container>
        <div className={styles.text}>
          {_rawTitle && (<h1 className={cn("h3", styles.title)}><PortableText blocks={_rawTitle} /></h1>)}
          {_rawSubtitle && (<h2 className={cn("h3 color-palette-color", styles.subtitle)}><PortableText blocks={_rawSubtitle} /></h2>)}
          {_rawContent && (<div className={cn("entry-content", styles.content)}><PortableText blocks={_rawContent} /></div>)}
          {button && button.url && (
            <>
              {button.blank ? (
                <a href={button.url} className={cn("btn", button.secondaryButton ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke" : "color-palette-pseudo-bg")} target="_blank" rel="noreferrer">
                  {button.text}
                  <Icon symbol="arrow-right" />
                </a>
              ) : (
                <Link to={button.url} className={cn("btn", button.secondaryButton ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke" : "color-palette-pseudo-bg")}>
                  {button.text}
                  <Icon symbol="arrow-right" />
                </Link>
              )}
            </>
          )}
          {buttonExtendedReality && buttonExtendedReality.url && (
            <>
              {buttonExtendedReality.blank ? (
                <a href={buttonExtendedReality.url} className={cn("btn", styles.bottomButton, buttonExtendedReality.secondaryButton ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke" : "color-palette-pseudo-bg")} target="_blank" rel="noreferrer">
                  {buttonExtendedReality.text}
                  <Icon symbol="arrow-right" />
                </a>
              ) : (
                <Link to={buttonExtendedReality.url} className={cn("btn",styles.bottomButton, buttonExtendedReality.secondaryButton ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke" : "color-palette-pseudo-bg")}>
                  {buttonExtendedReality.text}
                  <Icon symbol="arrow-right" />
                </Link>
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

export default ContentBlock;
