import React, { useState, useEffect } from "react";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import Icon from "./icon";
import PortableText from "./portableText";
//import Fancybox from "./fancybox";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "gatsby";
import loadable from "@loadable/component";

const Fancybox = loadable(() => import("./fancybox"));

import * as styles from "../styles/components/home-hero.module.css";

const Hero = (props) => {
  const { title, subtitle, _rawContent, button, image, videoButton } = props;

  // Get viewport size
  const hasWindow = typeof window !== "undefined";
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, []);
    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  return (
    <div className={cn(styles.root, "color-palette-border-light")} id="hero">
      <div className={cn("grid color-palette-border-light", styles.grid)}>
        <div className={cn("col-12 col-lg-7 col-xl-8", styles.col)}>
          <div className={styles.text}>
            {title && (
              <div className={cn(styles.title, "color-palette-color")}>
                {title}
              </div>
            )}
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            {_rawContent && (
              <div
                className={cn("entry-content", styles.content, styles.mHide)}
              >
                <PortableText blocks={_rawContent} />
              </div>
            )}
            {button &&
              (button.url || (button.document && button.document.asset)) && (
                <>
                  {button.document && button.document.asset ? (
                    <a
                      href={
                        button.document.asset.url +
                        "/" +
                        button.document.asset.originalFilename
                      }
                      className={cn(
                        "btn",
                        button.secondaryButton
                          ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke"
                          : "color-palette-pseudo-bg",
                        styles.button
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {button.text}
                      <Icon symbol="arrow-right" />
                    </a>
                  ) : (
                    <>
                      {button.blank ? (
                        <a
                          href={button.url}
                          className={cn(
                            "btn",
                            button.secondaryButton
                              ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke"
                              : "color-palette-pseudo-bg",
                            styles.button
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {button.text}
                          <Icon symbol="arrow-right" />
                        </a>
                      ) : (
                        <Link
                          to={button.url}
                          className={cn(
                            "btn",
                            button.secondaryButton
                              ? "secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke"
                              : "color-palette-pseudo-bg",
                            styles.button
                          )}
                        >
                          {button.text}
                          <Icon symbol="arrow-right" />
                        </Link>
                      )}
                    </>
                  )}
                  {videoButton && (
                    <div>
                      <Fancybox
                        options={{
                          dragToClose: false,
                          Hash: false,
                        }}
                      >
                        <a
                          href={videoButton.url}
                          className={cn(
                            "btn secondary color-palette-color color-palette-pseudo-bg-dark color-palette-svg-stroke",
                            styles.videoLink
                          )}
                          data-fancybox="hero"
                        >
                          {videoButton.text}
                          <Icon symbol="play" />
                        </a>
                      </Fancybox>
                    </div>
                  )}
                </>
              )}
          </div>
        </div>
        <div className={cn("col-auto col-lg-5 col-xl-4", styles.col)}>
          <div className={styles.image}>
            {image && image.asset && (
              <img
                src={imageUrlFor(buildImageObj(image)).auto("format").url()}
                alt={image.alt}
              />
            )}
          </div>
        </div>
      </div>
      <div className="color-palette-svg-stroke">
        <ScrollLink
          to="content"
          spy={false}
          smooth={true}
          hashSpy={false}
          offset={width >= 1500 ? -80 : width >= 960 ? -56 : -50}
          duration={800}
          delay={0}
          isDynamic={true}
          ignoreCancelEvents={false}
          spyThrottle={0}
          className={styles.btnScroll}
        >
          <Icon symbol="arrow-down" />
        </ScrollLink>
      </div>
    </div>
  );
};

export default Hero;
